import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SiteLayout from '../components/SiteLayout';

const AboutUsWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AboutUs = ({ }) => {
  const LAYOUT_KEY = 'ABOUT_US';
  return (
    <SiteLayout activeKey={LAYOUT_KEY}>
      <AboutUsWrapper>AboutUs</AboutUsWrapper>
    </SiteLayout>
  );
};

AboutUs.propTypes = {};

export default AboutUs;
